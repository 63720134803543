
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage } from '@ionic/vue';
import {  searchOutline, starOutline, settingsOutline } from 'ionicons/icons';
//import {  settings } from 'ionicons/dist/svg';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage },
  setup() {
    return {
	searchOutline, starOutline, settingsOutline,
    }
  }
}
